<template>
    <!-- Begin Trial Area -->
    <div class="trial-area trial-style-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="trial-item">
                        <span class="trial-category">Trial Version Available</span>
                        <h2 class="trial-title">Start your 5-day’s free trial.</h2>
                        <p class="trial-desc">That necessitates a robust ecommerce platform that optimizes
                            remaining unchanged your store & products
                        </p>
                        <div class="trial-btn-wrap">
                            <router-link to="/contact"><img src="images/button/5-1-213x73.png" alt="Button"></router-link>
                            <router-link to="/contact"><img src="images/button/5-2-213x73.png" alt="Button"></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Trial Area End Here -->
</template>
